<template>
  <v-list-item v-if="textLength > 0">
    <v-list-item-icon class="mr-4">
      <v-icon :small="small">
        {{ icon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="text">
        <div
          :class="{'collapsed-text': !expanded && canBeExpanded}"
          class="collapsableText"
          @click="expanded = true"
          ref="collapsableText"
          :style="{
            maxHeight: expanded ? 'none' : maxHeightBeforeCollapse + 'px',
          }"
        >
          <katex-md :expr="this.text" />
        </div>

        <a
          v-if="canBeExpanded"
          @click="expanded = true"
        >
          {{ learnMore }}
        </a>
        <a
          v-if="canBeCollapsed"
          @click="expanded = false"
          class="mt-2 d-block"
        >
          {{ seeLess }}
        </a>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import katexMd from "../../katex-md.vue"
import { computed, ref, watch } from "vue"
import { useResizeObserver } from "@vueuse/core"

export default {
  name: "QuizSeeMore",
  components: { katexMd },
  props: {
    icon: { type: String, default: "" },
    learnMore: { type: String, default: "" },
    seeLess: { type: String, default: "" },
    text: { type: String, default: "" },
    maxHeightBeforeCollapse: { type: Number, default: 100 },
    small: { type: Boolean, default: false },
  },
  setup(props) {
    const collapsableText = ref(null)

    const offsetHeight = ref(0)
    const scrollHeight = ref(0)
    const expanded = ref(false)


    const updateHeights = () => {
      if (collapsableText.value) {
        offsetHeight.value = collapsableText.value.offsetHeight
        scrollHeight.value = collapsableText.value.scrollHeight
      }
    }

    watch(collapsableText, updateHeights, { immediate: true })

    useResizeObserver(collapsableText, updateHeights)

    const canBeExpanded = computed(() => {
      return offsetHeight.value < scrollHeight.value
    })

    const canBeCollapsed = computed(() => {
      return offsetHeight.value > props.maxHeightBeforeCollapse
    })

    const textLength = computed(() => props.text.replace(/<[^>]+>/g, "").length)

    return { canBeExpanded, collapsableText, expanded, canBeCollapsed, textLength }
  },
}
</script>

<style>
  .text {
    white-space: normal !important;
    overflow-wrap: break-word;
    padding-right: 15px;
  }

  .collapsed-text {
    overflow: hidden;
    position: relative;
    -webkit-mask-image: linear-gradient(to bottom,black 30%,transparent 90%);
    mask-image: linear-gradient(to bottom,black 30%,transparent 90%);
   }
</style>
