import { computed, ref } from "vue"
import { useStore } from "@/store"
import { useIntervalFn, useTimeoutFn } from "@vueuse/core"
import timeHelpers from "@/helpers/time_helpers"

export function useQuizzesAttemptSummary(quizzesAttemptSummary) {

  const store = useStore()

  const timeAccommodation = computed(() => {
    return store.getters.timeAccommodation(quizzesAttemptSummary?.time_accommodation_id)
  })

  const expectedSessionState = ref("not_started")

  useTimeoutFn(() => {
    expectedSessionState.value = computeCurrentExpectedState()
  }, 1000)

  const computeCurrentExpectedState = () => {
    // update expectedSessionState regarding quizzesAttemptSummary.start_at and quizzesAttemptSummary.end_at vs current time. end_at may be null.
    // if start_at is in the future, return "scheduled"

    const now = new Date()
    const startAt = quizzesAttemptSummary?.start_at && new Date(quizzesAttemptSummary?.start_at)
    const endAt = quizzesAttemptSummary?.end_at && new Date(quizzesAttemptSummary?.end_at)

    if (startAt && startAt > now) return "scheduled"
    if (endAt && endAt < now) return "closed"
    return "open"
  }


  const timeOffset = store.getters.timeOffset
  const computeRemainingTimeMs = () => {
    if (!quizzesAttemptSummary?.submission_deadline_at) return
    return new Date(quizzesAttemptSummary.submission_deadline_at) - Date.now() - timeOffset
  }


  // TODO: check what happens if the submission_deadline_at changes
  useIntervalFn(() => {
    remainingTimeMs.value = computeRemainingTimeMs()
  }, 1000)

  const remainingTimeFormatted = computed(() => {
    return timeHelpers.formatDuration(remainingTimeMs.value)
  })

  const remainingTimeMs = ref(computeRemainingTimeMs())

  const remainingTime = computed(() => {
    return Math.round(remainingTimeMs.value / 1000)
  })

  const countdownProgress = computed(() => {
    const studentStartedAt = new Date(quizzesAttemptSummary?.student_started_at)
    const submissionDeadlineAt = new Date(quizzesAttemptSummary?.submission_deadline_at)
    const duration = submissionDeadlineAt - studentStartedAt

    return (duration - remainingTimeMs.value) / duration * 100
  })

  return {
    timeAccommodation,
    expectedSessionState,
    remainingTime,
    remainingTimeFormatted,
    countdownProgress,
  }
}
