<template>
  <div>
    <quiz-see-more
      icon="info_outline"
      :small="small"
      :short-text="$t('live.examOnboarding.instructions.title')"
      :learn-more="$t('live.examOnboarding.instructions.learnMore')"
      :see-less="$t('live.examOnboarding.instructions.seeLess')"
      :text="quizInstructions"
    />
    <quiz-see-more
      icon="filter_1"
      :small="small"
      :short-text="$t('live.examOnboarding.grade.title')"
      :learn-more="$t('live.examOnboarding.grade.learnMore')"
      :see-less="$t('live.examOnboarding.grade.seeLess')"
      :text="grading"
    />
  </div>
</template>

<script>
import QuizSeeMore from "./quiz_see_more.vue"

export default {
  name: "QuizGradingInfo",
  components: { QuizSeeMore },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    quizInstructions() {
      let instructions = this.quiz.instructions || ""
      return (instructions === "<p></p>") ? "" : instructions
    },
    grading() {
      let grading = this.quiz.grading_description || ""
      return (grading.length === 0 || grading === "<p></p>") ? this.$t("quiz.gradeRules") : grading
    },
  },
}
</script>

<style scoped>

</style>
