import { computed } from "vue"
import timeHelpers from "@/helpers/time_helpers"
import vueI18n from "@/plugins/i18n"
import { useQuizzesAttemptSummary } from "@/composables/useQuizzesAttemptSummary"


export function useQuizSummary({ quiz, quizzesAttemptSummary, session }) {
  const instructions = computed(() => {
    const formattedDuration = timeHelpers.formatDuration((quizzesAttemptSummary?.time_limit_with_accommodation || quiz.time_limit) * 1000)

    const timeAccommodation = useQuizzesAttemptSummary(quizzesAttemptSummary)?.timeAccommodation

    const durationLabel = quiz.time_limit ?
      vueI18n.t("live.timer", { timer: formattedDuration }) :
      vueI18n.t("live.examOnboarding.grading.noTimer")

    return [
      {
        icon: "mdi-format-list-checks",
        label: vueI18n.t(
          "live.examOnboarding.grading.questions",
          { questions: quiz.questions_count }
        ),
        visible: true,
      },
      {
        icon: "mdi-timer-outline",
        label: durationLabel,
        visible: quiz.quiz_type === "exam",
      },
      {
        icon: "mdi-undo",
        label: quiz.allow_questions_navigation ?
          vueI18n.t("live.examOnboarding.grading.navigation") :
          vueI18n.t("live.examOnboarding.grading.noNavigation"),
        visible: quiz.quiz_type === "exam",
      },
      {
        icon: "mdi-clock-outline",
        label: `${vueI18n.t("live.startTime")} ${ formatDate(quizzesAttemptSummary?.start_at) }`,
        visible: quizzesAttemptSummary?.start_at,
      },
      {
        icon: "mdi-clock-outline",
        label: `${vueI18n.t("live.startTime")} ${ formatDate(session?.start_at) }`,
        visible: session?.start_at && !quizzesAttemptSummary,
      },
      {
        icon: "mdi-clock-end",
        label: `${vueI18n.t("live.stopTime")} ${ formatDate(quizzesAttemptSummary?.end_at_with_accommodation) }`,
        visible: quizzesAttemptSummary?.end_at_with_accommodation,
      },
      {
        icon: "mdi-clock-end",
        label: `${vueI18n.t("live.stopTime")} ${ formatDate(session?.end_at) }`,
        visible: session?.end_at && !quizzesAttemptSummary,
      },
      {
        icon: "mdi-timer-plus-outline",
        label: vueI18n.t(
          "live.examOnboarding.grading.timeAccommodation",
          { name: timeAccommodation.value.name  }
        ),
        visible: quiz.time_accommodation && quizzesAttemptSummary,
      },
    ].filter(e => e.visible)
  })

  function formatDate(date) {
    return timeHelpers.formatDateTime(date) + " - " + Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  return {
    instructions,
  }
}
